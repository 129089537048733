/* eslint-disable max-lines */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { React, Settings, Theme } from '@/app'
import { View, Logo, Text, CenterWrapper, Link, Icon, Button } from '@/components'
import { variantProvider } from '@/app/theme'
import { navigate } from 'gatsby'
import { Image } from './Image'

type FooterProps = any

const contactMail = 'admin@wesnag.app'

export const Footer: React.FC<FooterProps> = () => {
  const currentYear = new Date().getFullYear()

  return (
    <View css={styles.wrapper}>
      <CenterWrapper variants={['row', 'fullWidth', 'justifySpaceBetween', 'padding:4']} styles={{ 'wrapper': styles.centerWrapper }}>
        <View
          css={styles.innerWrapper}
          variants={['justifySpaceBetween', 'alignStart']}
          responsiveVariants={{
            mid: ['gap:4', 'alignStart'],
          }}
        >
          <View variants={['column']}>
            <View variants={['column', 'gap:2']} responsiveVariants={{
            mid: ['center'],
          }}>
              <Image source={'images/footer.png'} css={[styles.logoImage]} />
              <Text text={'ConstructElevation Ltd'} variants={['white', 'marginTop:2']} />
              <Text variants={['white']} text={`${currentYear} © ${Settings.AppName}`} />
            </View>
          </View>
          <View variants={['gap:12']} responsiveVariants={{
            mid: ['column', 'gap:4'],
          }}>
            <View variants={['column', 'gap:3']} responsiveVariants={{
            mid: ['center'],
          }}>
              <Text variants={['h5', 'bold', 'white']} text={'Sitemap'} />
              <Text variants={['white']} style={styles.pointer} text={'Home'} onClick={() => navigate('/')} />
              <Text variants={['white']} style={styles.pointer} text={'Terms and Conditions'} onClick={() => navigate('/legal')} />
            </View>
            <View variants={['column', 'gap:3']} responsiveVariants={{
            mid: ['center'],
          }}>
              <Text variants={['h5', 'bold', 'white']} text={'Contact'} />
              <View variants={['alignCenter', 'gap:1']}>
                <Icon name={'mail'} />
                <Text variants={['white']} text={contactMail} />
              </View>
              <Link
                styles={{
                  'text': styles.mail
                }}
                to={`mailto:${contactMail}`}
                text={'GET IN TOUCH'} 
              />
            </View>
          </View>
        </View>
      </CenterWrapper>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.colors.primary,
      width: '100%',
      minHeight: 100,
    },
    centerWrapper: { 
      backgroundColor: theme.colors.blue700,
    },
    mail: {
      ...theme.presets.center,
      textAlign: 'center',
      ...theme.spacing.padding(1),
      borderRadius: theme.borderRadius.medium,
      backgroundColor: theme.colors.primary,
      color: theme.colors.blue700,
      transition: 'all 0.2s',

      '&:hover': {
        color: theme.colors.blue700,
        backgroundColor: theme.colors.blue400,
      }
    },
    footerLinkWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      [Theme.media.down('mid')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    linkCol: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      marginRight: Theme.spacing.value(4),
      [Theme.media.down('mid')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: Theme.spacing.value(2),
        marginLeft: Theme.spacing.value(2),
      },
    },
    link: {
      fontWeight: 'inherit',
      textDecorationLine: 'none',
      textDecorationColor: Theme.colors.light.black,
      color: 'black',
      '&:hover': {
        textDecorationStyle: 'solid',
        textDecorationLine: 'underline',
        opacity: 1,
      },
    },
    innerWrapper: {
      paddingVertical: Theme.spacing.value(4),
      flex: 1,
      display: 'flex',
      [Theme.media.down('mid')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
      },
    },
    text: {
      fontSize: 16,
    },
    regDetailsText: {
      color: 'black',
      opacity: 0.7,
      fontSize: 14,
      marginTop: Theme.spacing.value(2),
      [Theme.media.down('mid')]: {
        textAlign: 'center',
      },
    },
    inherit: {
      margin: 'inherit',
    },
    footerNavText: {
      marginTop: Theme.spacing.value(1),
      marginBottom: Theme.spacing.value(1),
      color: Theme.colors.light.black,
    },
    logoImage: {
      width: 200,
      alignSelf: 'flex-start',
      [theme.media.down('mid')]: {
        alignSelf: 'center',
      },
    },
    mobileDisclaimers: {
      flex: 1,
      marginBottom: Theme.spacing.value(2),
    },
    contactWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'column',
      [Theme.media.down('mid')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    disclaimersWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 3,
      [Theme.media.down('mid')]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    separator: {
      backgroundColor: 'black',
      height: 1,
      marginTop: Theme.spacing.value(3),
      marginBottom: Theme.spacing.value(1),
      width: '100%',
    },
    bottomRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    socialMediaWrapper: {
      marginTop: Theme.spacing.value(2),
    },
    socialMediaIconWrapper: {
      marginLeft: Theme.spacing.value(2),
      [Theme.media.down('mid')]: {
        marginLeft: Theme.spacing.value(1),
        marginRight: Theme.spacing.value(1),
      },
    },
    pointer: {
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }),
  true,
)
