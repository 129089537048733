import { AppSettings } from '@codeleap/common'
import Obfuscate from './Obfuscate'
import * as Sentry from '@sentry/browser'
const ENV = process.env.NODE_ENV as AppSettings['Environment']['Type']

const isDev = () => {
  if(typeof window === 'undefined'){
    return false
  }

  return ENV == 'development' || window.location.origin.includes('codeleap.co.uk')
}

const isDevEnv = isDev()


export const Settings: AppSettings = {
  AppName: 'WeSNAG',
  CompanyName: 'WeSNAG App Ltd',
  Description: 'Construction management',
  Environment: {
    IsDev: isDevEnv,
    Type: ENV,
  },
  Application: {
    IsBrowser: false,
  },
  Fetch: {
    ProductionURL: 'https://prod.wesnag.app/',
    DevelopmentURL: 'https://prod.wesnag.app/',
  },
  Social: {
    FaceURL: 'https://www.facebook.com/codeleapuk/',
    LinkedinURL: 'https://www.linkedin.com/company/codeleap-uk',
  },
  ContactINFO: {
    Website: 'codeleap.co.uk',
    TermsAndPrivacy: 'https://wesnag.app/legal/',
    SupportEMAIL: 'support@codeleap.co.uk',
    ContactEMAIL: 'hello@codeleap.co.uk',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
    ],
    Obfuscate,
  },
  Sentry: {
    enable: false,
    provider: Sentry,
    dsn: 'https://358ebbb4bd4e4ccc89d3d955f26b34e0@o309198.ingest.sentry.io/5824684',
    debug: false,
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId:
        '268760770384-a07ccfukq9vpngc6jcdiucjq0r53arte.apps.googleusercontent.com',
    },
    FacebookSDK: {
      AppId: '116173886421758',
    },
  },
}
